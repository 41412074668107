@import url("@fontsource-variable/rubik");

:root {
  /* Direction */
  --bl-text-x-direction: 1;

  /* Primary Color */
  --bl-color-primary: #f27a1a;
  --bl-color-primary-highlight: #ef6114;
  --bl-color-primary-contrast: #fef2e8;

  /* Success Color */
  --bl-color-success: #0bc15c;
  --bl-color-success-highlight: #09a44e;
  --bl-color-success-contrast: #e7f9ef;

  /* Danger Color */
  --bl-color-danger: #ff5043;
  --bl-color-danger-highlight: #ff3028;
  --bl-color-danger-contrast: #ffeeec;

  /* Warning Color */
  --bl-color-warning: #ffb600;
  --bl-color-warning-highlight: #ff9800;
  --bl-color-warning-contrast: #fff8e6;

  /* Info Color */
  --bl-color-info: #5794ff;
  --bl-color-info-highlight: #457eff;
  --bl-color-info-contrast: #eef4ff;

  /* Neutral Color */
  --bl-color-neutral-none: #000;
  --bl-color-neutral-darkest: #0f131a;
  --bl-color-neutral-darker: #273142;
  --bl-color-neutral-dark: #6e7787;
  --bl-color-neutral-light: #95a1b5;
  --bl-color-neutral-lighter: #afbbca;
  --bl-color-neutral-lightest: #f1f2f7;
  --bl-color-neutral-full: #fff;

  /* Size and Spacing */
  --bl-size-4xs: 0.125rem; /* 2px */
  --bl-size-3xs: 0.25rem; /* 4px */
  --bl-size-2xs: 0.5rem; /* 8px */
  --bl-size-xs: 0.75rem; /* 12px */
  --bl-size-s: 0.875rem; /* 14px */
  --bl-size-m: 1rem; /* 16px */
  --bl-size-l: 1.25rem; /* 20px */
  --bl-size-xl: 1.5rem; /* 24px */
  --bl-size-2xl: 2rem; /* 32px */
  --bl-size-3xl: 2.5rem; /* 40px */
  --bl-size-4xl: 3rem; /* 48px */
  --bl-size-5xl: 4rem; /* 64px */
  --bl-size-6xl: 5rem; /* 80px */

  /* Z-indexes */
  --bl-index-deep: -1;
  --bl-index-base: 1;
  --bl-index-popover: 100;
  --bl-index-tooltip: 200;
  --bl-index-sticky: 300;
  --bl-index-overlay: 400;
  --bl-index-dialog: 500;
  --bl-index-notification: 600;

  /* Typography */
  --bl-font-family: "Rubik Variable", sans-serif;

  /* Font weights */
  --bl-font-weight-light: 300;
  --bl-font-weight-regular: 400;
  --bl-font-weight-medium: 500;
  --bl-font-weight-semibold: 600;
  --bl-font-weight-bold: 700;

  /* Font sizes */
  --bl-font-size-2xs: 0.5rem; /* 8px */
  --bl-font-size-xs: 0.625rem; /* 10px */
  --bl-font-size-s: 0.75rem; /* 12px */
  --bl-font-size-m: 0.875rem; /* 14px */
  --bl-font-size-l: 1rem; /* 16px */
  --bl-font-size-xl: 1.25rem; /* 20px */
  --bl-font-size-2xl: 1.5rem; /* 24px */
  --bl-font-size-3xl: 1.75rem; /* 28px */
  --bl-font-size-4xl: 2rem; /* 32px */
  --bl-font-size-5xl: 3rem; /* 48px */
  --bl-font-size-6xl: 4rem; /* 64px */

  /* Border radius */
  --bl-border-radius-xs: 0.125rem; /* 2px */
  --bl-border-radius-s: 0.25rem; /* 4px */
  --bl-border-radius-m: 0.375rem; /* 6px */
  --bl-border-radius-l: 0.5rem; /* 8px */
  --bl-border-radius-pill: 62.438rem; /* 999px */
  --bl-border-radius-circle: 50%;

  /* Font Style: Display */
  --bl-font-display-font-size: var(--bl-font-size-5xl);
  --bl-font-display-line-height: calc(var(--bl-font-display-font-size) + var(--bl-size-2xs));
  --bl-font-display-size: var(--bl-font-display-font-size) / var(--bl-font-display-line-height);
  --bl-font-display: var(--bl-font-display-size) var(--bl-font-family);
  --bl-font-display-light: var(--bl-font-weight-light) var(--bl-font-display);
  --bl-font-display-regular: var(--bl-font-weight-regular) var(--bl-font-display);
  --bl-font-display-medium: var(--bl-font-weight-medium) var(--bl-font-display);
  --bl-font-display-semibold: var(--bl-font-weight-semibold) var(--bl-font-display);
  --bl-font-display-bold: var(--bl-font-weight-bold) var(--bl-font-display);

  /* Font Style: Heading 1 */
  --bl-font-heading-1-font-size: var(--bl-font-size-4xl);
  --bl-font-heading-1-line-height: calc(var(--bl-font-heading-1-font-size) + var(--bl-size-3xs));
  --bl-font-heading-1-size: var(--bl-font-heading-1-font-size) /
    var(--bl-font-heading-1-line-height);
  --bl-font-heading-1: var(--bl-font-heading-1-size) var(--bl-font-family);
  --bl-font-heading-1-regular: var(--bl-font-weight-regular) var(--bl-font-heading-1);
  --bl-font-heading-1-medium: var(--bl-font-weight-medium) var(--bl-font-heading-1);
  --bl-font-heading-1-semibold: var(--bl-font-weight-semibold) var(--bl-font-heading-1);
  --bl-font-heading-1-bold: var(--bl-font-weight-bold) var(--bl-font-heading-1);

  /* Font Style: Heading 2 */
  --bl-font-heading-2-font-size: var(--bl-font-size-3xl);
  --bl-font-heading-2-line-height: calc(var(--bl-font-heading-2-font-size) + var(--bl-size-3xs));
  --bl-font-heading-2-size: var(--bl-font-heading-2-font-size) /
    var(--bl-font-heading-2-line-height);
  --bl-font-heading-2: var(--bl-font-heading-2-size) var(--bl-font-family);
  --bl-font-heading-2-regular: var(--bl-font-weight-regular) var(--bl-font-heading-2);
  --bl-font-heading-2-medium: var(--bl-font-weight-medium) var(--bl-font-heading-2);
  --bl-font-heading-2-semibold: var(--bl-font-weight-semibold) var(--bl-font-heading-2);
  --bl-font-heading-2-bold: var(--bl-font-weight-bold) var(--bl-font-heading-2);

  /* Font Style: Heading 3 */
  --bl-font-heading-3-font-size: var(--bl-font-size-2xl);
  --bl-font-heading-3-line-height: calc(var(--bl-font-heading-3-font-size) + var(--bl-size-3xs));
  --bl-font-heading-3-size: var(--bl-font-heading-3-font-size) /
    var(--bl-font-heading-3-line-height);
  --bl-font-heading-3: var(--bl-font-heading-3-size) var(--bl-font-family);
  --bl-font-heading-3-regular: var(--bl-font-weight-regular) var(--bl-font-heading-3);
  --bl-font-heading-3-medium: var(--bl-font-weight-medium) var(--bl-font-heading-3);
  --bl-font-heading-3-semibold: var(--bl-font-weight-semibold) var(--bl-font-heading-3);
  --bl-font-heading-3-bold: var(--bl-font-weight-bold) var(--bl-font-heading-3);

  /* Font Style: Title 1 */
  --bl-font-title-1-font-size: var(--bl-font-size-xl);
  --bl-font-title-1-line-height: calc(var(--bl-font-title-1-font-size) + var(--bl-size-3xs));
  --bl-font-title-1-size: var(--bl-font-title-1-font-size) / var(--bl-font-title-1-line-height);
  --bl-font-title-1: var(--bl-font-title-1-size) var(--bl-font-family);
  --bl-font-title-1-regular: var(--bl-font-weight-regular) var(--bl-font-title-1);
  --bl-font-title-1-medium: var(--bl-font-weight-medium) var(--bl-font-title-1);
  --bl-font-title-1-semibold: var(--bl-font-weight-semibold) var(--bl-font-title-1);
  --bl-font-title-1-bold: var(--bl-font-weight-bold) var(--bl-font-title-1);

  /* Font Style: Title 2 */
  --bl-font-title-2-font-size: var(--bl-font-size-l);
  --bl-font-title-2-line-height: calc(var(--bl-font-title-2-font-size) + var(--bl-size-3xs));
  --bl-font-title-2-size: var(--bl-font-title-2-font-size) / var(--bl-font-title-2-line-height);
  --bl-font-title-2: var(--bl-font-title-2-size) var(--bl-font-family);
  --bl-font-title-2-regular: var(--bl-font-weight-regular) var(--bl-font-title-2);
  --bl-font-title-2-medium: var(--bl-font-weight-medium) var(--bl-font-title-2);
  --bl-font-title-2-semibold: var(--bl-font-weight-semibold) var(--bl-font-title-2);
  --bl-font-title-2-bold: var(--bl-font-weight-bold) var(--bl-font-title-2);

  /* Font Style: Title 3 */
  --bl-font-title-3-font-size: var(--bl-font-size-m);
  --bl-font-title-3-line-height: calc(var(--bl-font-title-3-font-size) + var(--bl-size-4xs));
  --bl-font-title-3-size: var(--bl-font-title-3-font-size) / var(--bl-font-title-3-line-height);
  --bl-font-title-3: var(--bl-font-title-3-size) var(--bl-font-family);
  --bl-font-title-3-regular: var(--bl-font-weight-regular) var(--bl-font-title-3);
  --bl-font-title-3-medium: var(--bl-font-weight-medium) var(--bl-font-title-3);
  --bl-font-title-3-semibold: var(--bl-font-weight-semibold) var(--bl-font-title-3);
  --bl-font-title-3-bold: var(--bl-font-weight-bold) var(--bl-font-title-3);

  /* Font Style: Title 4 */
  --bl-font-title-4-font-size: var(--bl-font-size-s);
  --bl-font-title-4-line-height: calc(var(--bl-font-title-4-font-size) + var(--bl-size-4xs));
  --bl-font-title-4-size: var(--bl-font-title-4-font-size) / var(--bl-font-title-4-line-height);
  --bl-font-title-4: var(--bl-font-title-4-size) var(--bl-font-family);
  --bl-font-title-4-regular: var(--bl-font-weight-regular) var(--bl-font-title-4);
  --bl-font-title-4-medium: var(--bl-font-weight-medium) var(--bl-font-title-4);
  --bl-font-title-4-semibold: var(--bl-font-weight-semibold) var(--bl-font-title-4);
  --bl-font-title-4-bold: var(--bl-font-weight-bold) var(--bl-font-title-4);

  /* Font Style: Body Text 1 */
  --bl-font-body-text-1-font-size: var(--bl-font-size-l);
  --bl-font-body-text-1-line-height: calc(
    var(--bl-font-body-text-1-font-size) + var(--bl-size-4xs)
  );
  --bl-font-body-text-1-size: var(--bl-font-body-text-1-font-size) /
    var(--bl-font-body-text-1-line-height);
  --bl-font-body-text-1: var(--bl-font-body-text-1-size) var(--bl-font-family);
  --bl-font-body-text-1-regular: var(--bl-font-weight-regular) var(--bl-font-body-text-1);
  --bl-font-body-text-1-medium: var(--bl-font-weight-medium) var(--bl-font-body-text-1);

  /* Font Style: Body Text 2 */
  --bl-font-body-text-2-font-size: var(--bl-font-size-m);
  --bl-font-body-text-2-line-height: calc(
    var(--bl-font-body-text-2-font-size) + var(--bl-size-4xs)
  );
  --bl-font-body-text-2-size: var(--bl-font-body-text-2-font-size) /
    var(--bl-font-body-text-2-line-height);
  --bl-font-body-text-2: var(--bl-font-body-text-2-size) var(--bl-font-family);
  --bl-font-body-text-2-regular: var(--bl-font-weight-regular) var(--bl-font-body-text-2);
  --bl-font-body-text-2-medium: var(--bl-font-weight-medium) var(--bl-font-body-text-2);

  /* Font Style: Body Text 3 */
  --bl-font-body-text-3-font-size: var(--bl-font-size-s);
  --bl-font-body-text-3-line-height: calc(
    var(--bl-font-body-text-3-font-size) + var(--bl-size-4xs)
  );
  --bl-font-body-text-3-size: var(--bl-font-body-text-3-font-size) /
    var(--bl-font-body-text-3-line-height);
  --bl-font-body-text-3: var(--bl-font-body-text-3-size) var(--bl-font-family);
  --bl-font-body-text-3-regular: var(--bl-font-weight-regular) var(--bl-font-body-text-3);
  --bl-font-body-text-3-medium: var(--bl-font-weight-medium) var(--bl-font-body-text-3);

  /* Font Style: Caption */
  --bl-font-caption-font-size: var(--bl-font-size-xs);
  --bl-font-caption-line-height: calc(var(--bl-font-caption-font-size) + var(--bl-size-4xs));
  --bl-font-caption-size: var(--bl-font-caption-font-size) / var(--bl-font-caption-line-height);
  --bl-font-caption: var(--bl-font-weight-medium) var(--bl-font-caption-size) var(--bl-font-family);
}

:root[dir="rtl"] {
  /* Direction */
  direction: rtl;

  --bl-text-x-direction: -1;

  /* Typography */
  --bl-font-family: "Cairo";
}
